import React, { useEffect, useState, useRef } from 'react';
import Footer from './Footer'
import Header from './Header1';
import AOS from 'aos';
import 'aos/dist/aos.css';
import logoicon from '../Assets/images/slide/openGPT.png'
import $ from "jquery";

import targets from "../Assets/images/slide/target.gif";
import colorcircle from "../Assets/images/slide/globe.gif";
import hud from "../Assets/images/slide/hud.gif";
import loopsimg from "../Assets/images/slide/loop-gif.gif";
import loopsleftimg from "../Assets/images/slide/loopleft.gif";
import launch from "../Assets/images/slide/launch1.gif";
import load1 from "../Assets/images/slide/load1.gif";
import top from "../Assets/images/slide/top.gif";
import top1 from "../Assets/images/slide/top1.gif";
import navmenuimg from "../Assets/images/slide/nav.gif";

import planet from "../Assets/images/slide/planet.gif";
import topborder from "../Assets/images/slide/corner.png";
import bottomborder from "../Assets/images/slide/corner1.png";
import hudgif from "../Assets/images/slide/hud1.gif";

import navitemmenu from "../Assets/images/slide/Navitemmenu.png";
import linearbg from "../Assets/images/slide/linearbg.png";

import grayborder from "../Assets/images/slide/grayborder.svg";
import blueborder from "../Assets/images/slide/bluecurve.svg";

import partner1 from "../Assets/images/slide/gs-logo-slider-2.png";
import partner2 from "../Assets/images/slide/partner1.png";
import partner3 from "../Assets/images/slide/Untitled-2.png";
import partner4 from "../Assets/images/slide/Untitled-3.png";

import glitchimg from "../Assets/images/slide/glitch.gif";
import screenimg from "../Assets/images/slide/screen.png";
import sustainable from "../Assets/images/slide/sustainable.png";

import sample from '../Assets/images/slide/bg_1.mp4';
import dock from '../Assets//images/slide/dock.png';

import leftcamera from '../Assets/images/slide/left-camera.gif';
import rightcamera from '../Assets//images/slide/right-camera.gif';
import power from '../Assets//images/slide/power-gauge.gif';

import spaceman from '../Assets//images/slide/flaot_FIN.gif';

import planet1 from '../Assets//images/slide/1.png';
import planet2 from '../Assets//images/slide/2.png';
import planet3 from '../Assets//images/slide/3.png';
import planet4 from '../Assets//images/slide/4.png';

import Typed from 'react-typed';

import { getSettingsData } from '../Hooks/UseBackend';
import toast, { Toaster } from 'react-hot-toast';
import { getAccount } from '../Hooks/useAccounts';
import { UseProvider } from '../Hooks/UseWeb3';
import {style,iconTheme,position} from '../Hooks/useToast';
import Connect from './Modal/ConnectModal';

const Home = () => {


    useEffect(() => {
        AOS.init({disable: 'mobile'});
        $(window).scroll(function () {
            var scroll = $(window).scrollTop();
            // if (scroll < 600){
            //     $(".ship").css({
            //         backgroundSize: (100 + scroll / 10) + "%",
            //         top: -(scroll / 10) + "%",
    
            //     });
            // }
            $(".ship").css({
                backgroundSize: (100 + scroll / 10) + "%",
                top: -(scroll / 10) + "%",

            });
            // $(".spaceship").css({
            //     backgroundSize: (100 + scroll / 5) + "%",
            //     top: -(scroll / 10) + "%",

            // });

        });
    }, []);

    
  useEffect(()=>{
    window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    setAccountInfo(getAccount());
    getData()
  },[])

    const[connect, setConnect] = useState(false)
    const [accountInfo, setAccountInfo] = useState("");
    const [settingdata,setsettingdata] = useState([]);

    const logOut = async () => {
        setAccountInfo("");
        localStorage.removeItem("accountInfo")
        if (localStorage.getItem("walletconnect") != null) {
            const provider = await UseProvider();
            await provider.disconnect()
        }
    
        
        toast.success("Wallet Disconnected successfully", {
          position:position.position,
          style:style,
          iconTheme: iconTheme,
      })
      setTimeout(() => {
          
          window.location.reload()
      }, 1000);
    }
    
       
    
        const getData =async() =>{
            var settingdata = await getSettingsData();
      setsettingdata(settingdata);
        }


    const [planetimg, setplanetimg] = useState([
        {
            "id": 1,
            "image": planet1,
            "title": "Oct 2022",
            "para": "Label M Network is over 99% more energy efficient than proof of work-based blockchain networks, providing artists with a more sustainable solution today while Ethereum upgrades to proof of stake",
        },
        {
            "id": 2,
            "image": planet2,
            "title": "Oct 2022",
            "para": "Label M Network is over 99% more energy efficient than proof of work-based blockchain networks, providing artists with a more sustainable solution today while Ethereum upgrades to proof of stake",
        },
        {
            "id": 3,
            "image": planet3,
            "title": "Oct 2022",
            "para": "Label M Network is over 99% more energy efficient than proof of work-based blockchain networks, providing artists with a more sustainable solution today while Ethereum upgrades to proof of stake",
        },
        {
            "id": 4,
            "image": planet4,
            "title": "Oct 2022",
            "para": "Label M Network is over 99% more energy efficient than proof of work-based blockchain networks, providing artists with a more sustainable solution today while Ethereum upgrades to proof of stake",
        },
    ]);

    const [partnerimg, setpartnerimg] = useState([
        {
            "id": 1,
            "image": partner1,
        },
        {
            "id": 2,
            "image": partner4,
        },
        {
            "id": 3,
            "image": partner3,
        },
        {
            "id": 4,
            "image": partner4,
        },
        {
            "id": 5,
            "image": partner1,
        },
        {
            "id": 6,
            "image": partner4,
        },
        {
            "id": 7,
            "image": partner3,
        },
        {
            "id": 8,
            "image": partner4,
        },
        {
            "id": 9,
            "image": partner1,
        },
        {
            "id": 10,
            "image": partner4,
        },
        {
            "id": 11,
            "image": partner3,
        },
        {
            "id": 12,
            "image": partner4,
        },
        {
            "id": 13,
            "image": partner4,
        },
        {
            "id": 14,
            "image": partner4,
        },
        {
            "id": 15,
            "image": partner4,
        },
        {
            "id": 16,
            "image": partner4,
        },
    ])

    const bannerSection = useRef(null);
    const paraSection = useRef(null);
    const partnerSection = useRef(null);
    const featuresSection = useRef(null);
    const roadmapSection = useRef(null);

    const [hari, setHari] = useState({
        first: true,
        second: false,
        third: false,
        four: false,
        five: false
    })

    const scrollDown = (ref, value) => {
        switch (value) {
            case "first":
                var data = { ...hari }
                data.first = true
                data.second = false
                data.third = false
                data.four = false
                data.five = false
                setHari(data)
                break;
            case "second":
                var data = { ...hari }
                data.first = false
                data.second = true
                data.third = false
                data.four = false
                data.five = false
                setHari(data)
                break;
            case "third":
                var data = { ...hari }
                data.first = false
                data.second = false
                data.third = true
                data.four = false
                data.five = false
                setHari(data)
                break;
            case "four":
                var data = { ...hari }
                data.first = false
                data.second = false
                data.third = false
                data.four = true
                data.five = false
                setHari(data)
                break;
            case "five":
                var data = { ...hari }
                data.first = false
                data.second = false
                data.third = false
                data.four = false
                data.five = true
                setHari(data)
                break;
        }
        window.scrollTo({
            top: ref.current.offsetTop,
            behavior: 'smooth',
        });
    };

    return (
        <>
            <div className='Homepage'>
            {connect && <Connect onDismiss={() => setConnect(false)}/>}
                <div className='navigationmenu'>
                    <img src={navmenuimg} className='navborder1' alt='targets' />
                    <img src={navitemmenu} className='navborder' alt='targets' />
                    <ul>
                        <li onClick={() => { scrollDown(bannerSection, "first") }}><img src={hari.first ? blueborder : grayborder} className='grayborder' alt='targets' />  </li>
                        <li onClick={() => { scrollDown(paraSection, "second") }}><img src={hari.second ? blueborder : grayborder} className='grayborder' alt='targets' /></li>
                        <li onClick={() => { scrollDown(partnerSection, "third") }}><img src={hari.third ? blueborder : grayborder} className='grayborder' alt='targets' /></li>
                        <li onClick={() => { scrollDown(featuresSection, "four") }}><img src={hari.four ? blueborder : grayborder} className='grayborder' alt='targets' /></li>
                        <li onClick={() => { scrollDown(roadmapSection, "five") }}><img src={hari.five ? blueborder : grayborder} className='grayborder' alt='targets' /></li>
                        {/* <li class="home active"><a href="#home">Home</a></li>
                        <li class="about"><a href="#about">About</a></li>
                        <li class="contact"><a href="#contact">Contact</a></li>
                        <li class="footer"><a href="#footer">Footer</a></li> */}
                    </ul>
                </div>

                <div className='section1' id="home" ref={bannerSection}>
                    <div className='openbook'>
                        <span className='redgredient'></span>
                        <div className='homeheaders'>
                            <Header />
                        </div>
                        <img src={loopsimg} className='loopsimg' alt='targets' />
                      
                        <img src={loopsleftimg} className='loopsleftimg' alt='targets' />
                        {(accountInfo == "" || accountInfo == null)?
                    <button className='btn userbtn headingbtn one1 ms-lg-3' onClick={() => setConnect(true)}>Connect</button> :
                    <button className='btn userbtn headingbtn one1 ms-lg-3' onClick={() => logOut()}>{`${accountInfo.substring(0, 5)}...${accountInfo.substring(38,42)}`}</button>}
                       <img src={logoicon} className='logoimg' alt='targets' />
                        <img src={load1} className='loadimg' alt='targets' />
                        <img src={targets} className='targetcircle' alt='targets' />
                        <img src={colorcircle} className='globecircle' alt='targets' />
                        <img src={hud} className='hud' alt='targets' />
                        <img src={launch} className='launch' alt='targets' />
                        {/* <h5 className='launch-name'>Launch</h5> */}
                        <img src={top} className='bottomgif' alt='targets' />
                        <img src={top1} className='topgif' alt='targets' />
                        {/* <img src={navmenuimg} className='navmenus' alt='targets' /> */}
                        <span className='violentgredient'></span>
                        <div className='homesec'>
                            <h3 className='hometitle'>Welcome to OpenGPT</h3>
                            <p className='subtitle'>
                                <Typed
                                    strings={['Lorem ipsum dolor sit amet lorem']}
                                    typeSpeed={130}
                                    cursorChar={"_"}
                                // loop
                                /></p>
                            {/* <p className='subtitle'>Lorem ipsum dolor sit amet lorem</p> */}
                        </div>
                    </div>
                </div>


                <div className='section2' id="about" ref={paraSection}>
                    <div className='openbook'>
                        <img src={colorcircle} className='globecircle' alt='targets' />
                        <img src={planet} className='planetcircle' alt='targets' />
                        <div className='container seccon'>
                            <div className='ty ty1'>
                                <div className='row'>
                                    <div className='col-md-7'>
                                        <p className='fw-700'>OPI Information</p>
                                        {/* <p className='fw-700'>
                                            <Typed
                                                strings={['OPI Information']}
                                                typeSpeed={130}
                                                cursorChar={"_"}
                                            // loop
                                            /></p> */}
                                             <div className='mind'>
                                            <p className='mb-0 f-13'>
                                            <Typed
                                                strings={['Sell 1 (90 days locked stacking)']}
                                                typeSpeed={80}
                                                cursorChar={"_"}
                                            // loop
                                            /></p>
                                                <p className='mb-0 f-13'>
                                            <Typed
                                                strings={['Price 1 USDT = 40 LBM']}
                                                typeSpeed={80}
                                                cursorChar={"_"}
                                            // loop
                                            /></p>
                                                <p className='mb-0 f-13'>
                                            <Typed
                                                strings={['Reward: 0.05%']}
                                                typeSpeed={80}
                                                cursorChar={"_"}
                                            // loop
                                            /></p>
                                                <p className='mb-0 f-13'>
                                            <Typed
                                                strings={['Sale: Stopped']}
                                                typeSpeed={80}
                                                cursorChar={"_"}
                                            // loop
                                            /></p>
                                            </div>
                                        {/* <p data-aos="fade-right" data-aos-duration="1000" className='mb-0 f-13'>Sell 1 (90 days locked stacking)</p>
                                        <p data-aos="fade-right" data-aos-duration="1000" className='mb-0 f-13'>Price 1 USDT = 40 LBM</p>
                                        <p data-aos="fade-right" data-aos-duration="1000" className='mb-0 f-13'>Reward: 0.05%</p>
                                        <p data-aos="fade-right" data-aos-duration="1000" className='mb-0 f-13'>Sale: Stopped</p> */}
                                        <div className='pt-4 mind'>
                                            {/* <p className='mb-0 f-13'>Sell 2 [180 days locked staking]</p>
                                            <p className='mb-0 f-13'>Price 1 USDT = 40 LBM</p>
                                            <p className='mb-0 f-13'>Reward: 2%</p>
                                            <p className='mb-0 f-13'>Sale: Stopped</p> */}
                                            <p className='mb-0 f-13'>
                                            <Typed
                                                strings={['Sell 2 (180 days locked stacking)']}
                                                typeSpeed={80}
                                                cursorChar={"_"}
                                            // loop
                                            /></p>
                                                <p className='mb-0 f-13'>
                                            <Typed
                                                strings={['Price 1 USDT = 40 LBM']}
                                                typeSpeed={80}
                                                cursorChar={"_"}
                                            // loop
                                            /></p>
                                                <p className='mb-0 f-13'>
                                            <Typed
                                                strings={['Reward: 0.05%']}
                                                typeSpeed={80}
                                                cursorChar={"_"}
                                            // loop
                                            /></p>
                                                <p className='mb-0 f-13'>
                                            <Typed
                                                strings={['Sale: Stopped']}
                                                typeSpeed={80}
                                                cursorChar={"_"}
                                            // loop
                                            /></p>
                                        </div>
                                        <div className='pt-4 mind'>
                                            {/* <p className='mb-0 f-13'>Sell 3 [180 days locked staking]</p>
                                            <p className='mb-0 f-13'>Price 1 USDT = 40 LBM</p>
                                            <p className='mb-0 f-13'>Reward: 2%</p>
                                            <p className='mb-0 f-13'>Sale: Stopped</p> */}
                                            <p className='mb-0 f-13'>
                                            <Typed
                                                strings={['Sell 3 (180 days locked stacking)']}
                                                typeSpeed={80}
                                                cursorChar={"_"}
                                            // loop
                                            /></p>
                                                <p className='mb-0 f-13'>
                                            <Typed
                                                strings={['Price 1 USDT = 40 LBM']}
                                                typeSpeed={80}
                                                cursorChar={"_"}
                                            // loop
                                            /></p>
                                                <p className='mb-0 f-13'>
                                            <Typed
                                                strings={['Reward: 0.05%']}
                                                typeSpeed={80}
                                                cursorChar={"_"}
                                            // loop
                                            /></p>
                                                <p className='mb-0 f-13'>
                                            <Typed
                                                strings={['Sale: Stopped']}
                                                typeSpeed={80}
                                                cursorChar={"_"}
                                            // loop
                                            /></p>
                                        </div>
                                    </div>
                                    <div className='col-md-4 mt-top'>
                                        <div className='linear-rel pb-4'>
                                            <h5 className='mb-0 ms-3 fw-700'>Why OpenGPT?</h5>
                                            <img src={linearbg} className='linearbg' alt='targets' />
                                        </div>

                                        <div className='para pt-3'>
                                            <img src={topborder} className='topborder' alt='targets' />
                                            <p className='mb-0 f-13 subparas'>
                                                <Typed
                                                    strings={['Together, with some of the biggest names in fine art, Crypto art and entertainment we are creating a new studios and NFT Platforms on BNB chain that is both scalable and sustainable.']}
                                                    typeSpeed={130}
                                                    cursorChar={"_"}
                                                // loop
                                                /></p>
                                            {/* <p className='mb-0 f-13 subparas'>Together, with some of the biggest
                                                names in fine art, Crypto art and
                                                entertainment we are creating a
                                                new studios and NFT Platforms on
                                                BNB chain that is both scalable
                                                and sustainable.</p> */}
                                            <img src={bottomborder} className='bottomborder' alt='targets' />
                                        </div>

                                        <div className='para pt-5'>
                                            {/* <img src={hudgif} className='hudgif' alt='targets' /> */}
                                            <img src={hudgif} className='hudgifs' alt='targets' />
                                            <p className='mb-0 f-11 subparas' >DeltaTime12.32.1234.21</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='section3' ref={partnerSection}>
                    {/* <div className='ship'> */}
                    {/* <img src={glitchimg} className='videoTag' alt='targets' /> */}
                    {/* <img src={screenimg} className='screenimg' alt='targets' /> */}
                    {/* <img src={dock} className='dock w-100' alt='targets' /> */}
                    <video className='videoTag' autoPlay loop muted>
                        <source src={sample} type='video/mp4' />
                    </video>
                    {/* <img src={glitchimg} className='glitchimg' alt='targets' /> */}
                    <div className='container seccon1'>
                        <div className='row'>
                            {partnerimg.map((e, i) =>
                                <div className='col-md-3 col-4 text-center' data-aos="fade-up" data-aos-duration="1000">
                                    <img src={e.image} className='img-fluid w-100 mt-4 partner1' alt='targets' />
                                </div>
                            )}
                        </div>
                    </div>
                    {/* </div> */}
                </div>

                <div className='section4' ref={featuresSection}>
                    <div className='ship ree'>
                        <img src={screenimg} className='screenimg' alt='targets' />
                        <img src={leftcamera} className='leftcamera' alt='targets' />
                        <img src={rightcamera} className='rightcamera' alt='targets' />
                        <div className='container seccon2'>
                            <div className='text-center'>
                                <h3>Features of OpenGPT</h3>
                                <p className='sub-heading'>
                                    <Typed
                                        strings={['Designing a sustainable futures of NFTs on metaverse']}
                                        typeSpeed={130}
                                        cursorChar={"_"}
                                    // loop
                                    /></p>
                                {/* <p className='sub-heading'>Designing a sustainable futures of NFTs on metaverse</p> */}
                            </div>
                            <div className='ty'>
                                <div className='row pt-3'>
                                    <div className='col-md-3 col-3 big pb-3 text-center' data-aos="fade-up" data-aos-duration="1000">
                                        <img src={sustainable} className='sustainable' alt='targets' />
                                    </div>
                                    <div className='col-md-8 col-9 big pb-3' data-aos="fade-up" data-aos-duration="1000">
                                        <h6>Scalable and Sustainable</h6>
                                        <p className='f-12'>Together, with some of the biggest names in fine art, Crypto art and entertainment we are creating a new studios and NFT Platforms
                                            on BNB chain that is bothscalable and sustainable</p>
                                    </div>
                                    <div className='col-md-3 col-3 big  pb-3 text-center' data-aos="fade-up" data-aos-duration="1500">
                                        <img src={sustainable} className='sustainable' alt='targets' />
                                    </div>
                                    <div className='col-md-8 col-9 big  pb-3' data-aos="fade-up" data-aos-duration="1500">
                                        <h6>Invested in creativity</h6>
                                        <p className='f-12'>Together, with some of the biggest names in fine art, Crypto art and entertainment we are creating a new studios and NFT Platforms
                                            on BNB chain that is bothscalable and sustainable</p>
                                    </div>
                                    <div className='col-md-3 col-3 big  pb-3 text-center'  data-aos="fade-up" data-aos-duration="2000">
                                        <img src={sustainable} className='sustainable' alt='targets' />
                                    </div>
                                    <div className='col-md-8 col-9 big  pb-3'  data-aos="fade-up" data-aos-duration="2000">
                                        <h6>Built for Flexibility</h6>
                                        <p className='f-12'>Together, with some of the biggest names in fine art, Crypto art and entertainment we are creating a new studios and NFT Platforms
                                            on BNB chain that is bothscalable and sustainable</p>
                                    </div>
                                </div>
                                <div className='row pt-0'
                                //  data-aos="zoom-in" data-aos-duration="2000"
                                 >
                                    <div className='col-md-3 big1 col-3 pb-3' >
                                        <img src={power} className='power-gauge' alt='targets' />
                                    </div>
                                    <div className='col-md-9 big1 col-9 pb-3'>
                                        <h3>
                                            <Typed
                                                strings={['99.9% More Energy Efficient']}
                                                typeSpeed={130}
                                                cursorChar={"_"}
                                            // loop
                                            /></h3>
                                        {/* <h3>99.9% More Energy Efficient</h3> */}
                                        <p className='f-12'>Label M Network is over 99% more energy efficient than proof of work-based blockchain networks, providing artists with a more sustainable solution today while Ethereum upgrades to proof of stake</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='section5' ref={roadmapSection}>
                    <div className='ship'>
                        <img src={screenimg} className='screenimg' alt='targets' />
                        <img src={spaceman} className='spaceman' alt='targets' />
                        <div className='container seccon2'>
                            <div className='text-center'>
                                <h3>Roadmap of OpenGPT</h3>
                                <p className='f-14 pb-3'><Typed
                                    strings={['Our mission is to assist you to ensure your NFT & Metaverse project not just succeed, but blow away all expectations.']}
                                    typeSpeed={130}
                                    cursorChar={"_"}
                                // loop
                                /></p>
                                {/* <p className='f-14 pb-3'>Our mission is to assist you to ensure your NFT & Metaverse project not just
                                    succeed, but blow away all expectations.</p> */}
                            </div>
                            <div className='ty'>
                                {planetimg.map((e, i) =>
                                    <div className='row' data-aos="fade-right" data-aos-duration="2000">
                                        <div className='col-md-2 big2 col-3 pb-2'>
                                            <img src={e.image} className='planet1' alt='targets' />
                                        </div>
                                        <div className='col-md-10 big2 col-9 pb-2'>
                                            <h6>{e.title}</h6>
                                            <p className='f-12'>{e.para}</p>
                                        </div>
                                    </div>
                                )}
                                <div className='footer1 text-center'>
                                    <p className='f-11 mb-0 mt-2'>Copyright @ 2023. All rights reserved</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </div>

        </>
    )


}

export default Home;  