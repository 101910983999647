import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import navmenulist from "../Assets/images/slide/menu.gif";
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import listimg from "../Assets/images/slide/list.png";
import AOS from 'aos';
import 'aos/dist/aos.css';

import { getSettingsData } from '../Hooks/UseBackend';
import toast, { Toaster } from 'react-hot-toast';
import { getAccount } from '../Hooks/useAccounts';
import { UseProvider } from '../Hooks/UseWeb3';
import { style, iconTheme, position } from '../Hooks/useToast';
import Connect from './Modal/ConnectModal';

const Header = () => {

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    setAccountInfo(getAccount());
    getData()
  }, [])

  const [connect, setConnect] = useState(false)
  const [accountInfo, setAccountInfo] = useState("");
  const [settingdata, setsettingdata] = useState([]);

  const logOut = async () => {
    setAccountInfo("");
    localStorage.removeItem("accountInfo")
    if (localStorage.getItem("walletconnect") != null) {
      const provider = await UseProvider();
      await provider.disconnect()
    }


    toast.success("Wallet Disconnected successfully", {
      position: position.position,
      style: style,
      iconTheme: iconTheme,
    })
    setTimeout(() => {

      window.location.reload()
    }, 1000);
  }



  const getData = async () => {
    var settingdata = await getSettingsData();
    setsettingdata(settingdata);
  }


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className='headergpt'>
      {connect && <Connect onDismiss={() => setConnect(false)} />}
      <div className='headers'>
        <ul>
          <li data-aos="fade-right" data-aos-duration="1000"><Link to="/"><img src={navmenulist} className='navmenulist' alt='targets' /> Home</Link></li>
          <li data-aos="fade-right" data-aos-duration="1800" className='ms-2'><Link to="/launchpad"><img src={navmenulist} className='navmenulist' alt='targets' /> Launchpad</Link></li>
          <li data-aos="fade-right" data-aos-duration="2200" className='ms-3'><Link to="/staking"><img src={navmenulist} className='navmenulist' alt='targets' /> Locked Staking</Link></li>
          <li data-aos="fade-right" data-aos-duration="2600" className='ms-4'><Link to="/news"><img src={navmenulist} className='navmenulist' alt='targets' /> News</Link></li>
          <li data-aos="fade-right" data-aos-duration="4000" className='ms-5'><Link to="/map"><img src={navmenulist} className='navmenulist' alt='targets' /> Map</Link></li>
        </ul>
      </div>

      <div className='headers1'>
        <Button variant="primary" onClick={handleShow}>
          <img src={listimg} className='listimg' alt='targets' />
        </Button>

        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>OpenGPT</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ul>
              <li><Link to="/"><img src={navmenulist} className='navmenulist' alt='targets' /> Home</Link></li>
              <li className='ms-2'><Link to="/launchpad"><img src={navmenulist} className='navmenulist' alt='targets' /> Launchpad</Link></li>
              <li className='ms-3'><Link to="/staking"><img src={navmenulist} className='navmenulist' alt='targets' /> Locked Staking</Link></li>
              <li className='ms-4'><Link to="/news"><img src={navmenulist} className='navmenulist' alt='targets' /> News</Link></li>
              <li className='ms-5'><Link to="/map"><img src={navmenulist} className='navmenulist' alt='targets' /> Map</Link></li>
              <li className='mt-3'>
                {(accountInfo == "" || accountInfo == null) ?
                  <button className='btn userbtn headingbtn ms-3 ms-lg-3' onClick={() => setConnect(true)}>Connect</button> :
                  <button className='btn userbtn headingbtn ms-3 ms-lg-3' onClick={() => logOut()}>{`${accountInfo.substring(0, 5)}...${accountInfo.substring(38, 42)}`}</button>}
              </li>
            </ul>

          </Offcanvas.Body>
        </Offcanvas>
      </div>

    </div>



  )
}


export default Header;
