export const  style = {                     
    minWidth: '300px',
    minHeight: '55px',
    border: '3px solid #00D1FF',
    padding: '16px',
    color: '#fff',
    background: '#000',
    
}

export const iconTheme = {
    primary: '#F6DF2D',
    secondary: '#000',
}

export const  position= {
    position: "top-center"
}