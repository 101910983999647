import React, { useEffect, useState } from 'react';
import { getAccount } from '../Hooks/useAccounts';
import { getPoolInfoLimit, getPoolLength, getUser } from '../Hooks/useStake';
import Footer from './Footer';
import Header from './Header';
import LockedStackCont from './LockedStackCont';
// import logoicon from '../Assets/images/logoicon.png'
import loopsimg from "../Assets/images/slide/loop-gif.gif";
import loopsleftimg from "../Assets/images/slide/loopleft.gif";
import targets from "../Assets/images/slide/target.gif";
const LockedStake = () => {
    const [poolLimit, setPoolLimit] = useState([]);
    const [poolLength, setPoolLength] = useState(0);
    const [accountInfo, setAccountInfo] = useState(getAccount());

    useEffect(() => {
        getData();
    })


    const getData = async () => {


        var data = await getPoolLength()
        if (data) setPoolLength(data);
        var pool = await getPoolInfoLimit(0, data)

        if (pool) {
            setPoolLimit(pool);
        }


    }
    return (
        <div className='lockedstake'>

            <Header />
            <div className='allsection'>
                <div className='container container-theme mt-4'>
                    {/* <div className='showloader minwidth-534 text-center' id="loader_div">
                <div className='loader ' >
                    <img src={logoicon} className="spin" />
                    
                </div>

            </div> */}
                    <div className='br-14 bgtheme text-dark p-sm-5 p-4 text-center bannerbox d-flex align-items-center justify-content-center position-relative'>
                        <div class="lines">
                            <div class="line1"></div>
                            <div class="line1"></div>
                            <div class="line1"></div>
                            <div class="line1"></div>
                        </div>
                        <span className='greengradientleft'></span>
                        <span className='greengradientright'></span>
                        <img src={loopsimg} className='loopsimg' alt='targets' />
                        <img src={loopsleftimg} className='loopsleftimg' alt='targets' />
                        <img src={targets} className='targetcircle' alt='targets' />
                        {/* <img className='img-fluid circlelanch' src={circle} alt='alirobo' /> */}
                        <h1 className='innerheading'>Locked Staking</h1>
                    </div>
                    <div className='row mb-5'>

                        {poolLimit
                            && poolLimit.map((val, index) => <LockedStackCont datas={val} pid={index} accountInfo={accountInfo} />
                            )}


                        {/* <div className='col-lg-4 col-md-6'>
                        <div className='launchbox p-sm-4 p-3 br-14 mt-sm-5 mt-3'>
                            <div className='d-flex align-items-center jc-between'>
                                <div className='logocont'><img src={logoicon} /> <span>LAbel M</span></div>  
                                <div>
                                <button className='ovalbtn'>Days</button>
                                </div>
                            </div>
                            
                            <div className='d-flex jc-between align-items-center mt-4'>
                                <p className='mb-0'>Unstake available after</p>
                            <button className='chocobtn'>LIVE</button>
                            </div>
                            <div className='bordbox p-4 br-14 my-3'>
                                <div className='d-flex jc-between gap-2'>
                                    <div>
                                    <div>
                                        <p className='mb-1'>Staked</p>
                                        <p>0.00</p>
                                    </div>
                                    <div>
                                        <p className='mb-1'>Earner MASP:</p>
                                        <p>0.00</p>
                                    </div>
                                    </div>
                                    
                                    <div>
                                        <p className='mb-1'>APR</p>
                                        <p><img src={calc} className="calcicon"/> 2%</p>
                                    </div>
                                </div>
                            </div>
                            <div className='text-center mt-5 mb-4'>                             
                            <button className='btn getstarted'>Approve Contract</button></div>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6'>
                        <div className='launchbox p-sm-4 p-3 br-14 mt-sm-5 mt-3'>
                            <div className='d-flex align-items-center jc-between'>
                                <div className='logocont'><img src={logoicon} /> <span>LAbel M</span></div>  
                                <div>
                                <button className='ovalbtn'>Days</button>
                                </div>
                            </div>
                            
                            <div className='d-flex jc-between align-items-center mt-4'>
                                <p className='mb-0'>Unstake available after</p>
                            <button className='chocobtn'>LIVE</button>
                            </div>
                            <div className='bordbox p-4 br-14 my-3'>
                                <div className='d-flex jc-between gap-2'>
                                    <div>
                                    <div>
                                        <p className='mb-1'>Staked</p>
                                        <p>0.00</p>
                                    </div>
                                    <div>
                                        <p className='mb-1'>Earner MASP:</p>
                                        <p>0.00</p>
                                    </div>
                                    </div>
                                    
                                    <div>
                                        <p className='mb-1'>APR</p>
                                        <p><img src={calc} className="calcicon"/> 5%</p>
                                    </div>
                                </div>
                            </div>
                            <div className='text-center mt-5 mb-4'> 
                            <button className='btn getstarted'>Unlock Wallet</button></div>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6'>
                        <div className='launchbox p-sm-4 p-3 br-14 mt-sm-5 mt-3'>
                            <div className='d-flex align-items-center jc-between'>
                                <div className='logocont'><img src={logoicon} /> <span>LAbel M</span></div>  
                                <div>
                                <button className='ovalbtn'>Days</button>
                                </div>
                            </div>
                            
                            <div className='d-flex jc-between align-items-center mt-4'>
                                <p className='mb-0'>Unstake available after</p>
                            <button className='chocobtn'>LIVE</button>
                            </div>
                            <div className='bordbox p-4 br-14 my-3'>
                                <div className='d-flex jc-between gap-2'>
                                    <div>
                                    <div>
                                        <p className='mb-1'>Staked</p>
                                        <p>0.00</p>
                                    </div>
                                    <div>
                                        <p className='mb-1'>Earner MASP:</p>
                                        <p>0.00</p>
                                    </div>
                                    </div>
                                    
                                    <div>
                                        <p className='mb-1'>APR</p>
                                        <p><img src={calc} className="calcicon"/> 0.05%</p>
                                    </div>
                                </div>
                            </div>
                            <div className='text-center mt-5 mb-4'> 
                            <button className='btn getstarted'>Unlock Wallet</button></div>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6'>
                        <div className='launchbox p-sm-4 p-3 br-14 mt-sm-5 mt-3'>
                            <div className='d-flex align-items-center jc-between'>
                                <div className='logocont'><img src={logoicon} /> <span>LAbel M</span></div>  
                                <div>
                                <button className='ovalbtn'>Days</button>
                                </div>
                            </div>
                            
                            <div className='d-flex jc-between align-items-center mt-4'>
                                <p className='mb-0'>Unstake available after</p>
                            <button className='chocobtn'>LIVE</button>
                            </div>
                            <div className='bordbox p-4 br-14 my-3'>
                                <div className='d-flex jc-between gap-2'>
                                    <div>
                                    <div>
                                        <p className='mb-1'>Staked</p>
                                        <p>0.00</p>
                                    </div>
                                    <div>
                                        <p className='mb-1'>Earner MASP:</p>
                                        <p>0.00</p>
                                    </div>
                                    </div>
                                    
                                    <div>
                                        <p className='mb-1'>APR</p>
                                        <p><img src={calc} className="calcicon"/> 2%</p>
                                    </div>
                                </div>
                            </div>
                            <div className='text-center mt-5 mb-4'>                             
                            <button className='btn getstarted'>Unlock Wallet</button></div>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6'>
                        <div className='launchbox p-sm-4 p-3 br-14 mt-sm-5 mt-3'>
                            <div className='d-flex align-items-center jc-between'>
                                <div className='logocont'><img src={logoicon} /> <span>LAbel M</span></div>  
                                <div>
                                <button className='ovalbtn'>Days</button>
                                </div>
                            </div>
                            
                            <div className='d-flex jc-between align-items-center mt-4'>
                                <p className='mb-0'>Unstake available after</p>
                            <button className='chocobtn'>LIVE</button>
                            </div>
                            <div className='bordbox p-4 br-14 my-3'>
                                <div className='d-flex jc-between gap-2'>
                                    <div>
                                    <div>
                                        <p className='mb-1'>Staked</p>
                                        <p>0.00</p>
                                    </div>
                                    <div>
                                        <p className='mb-1'>Earner MASP:</p>
                                        <p>0.00</p>
                                    </div>
                                    </div>
                                    
                                    <div>
                                        <p className='mb-1'>APR</p>
                                        <p><img src={calc} className="calcicon"/> 5%</p>
                                    </div>
                                </div>
                            </div>
                            <div className='text-center mt-5 mb-4'> 
                            <button className='btn getstarted'>Unlock Wallet</button></div>
                        </div>
                    </div> */}

                    </div>


                </div>
            </div>
            <Footer />
        </div>
    )
}

export default LockedStake